import { ConferenceBadgeData } from 'api-client/api';
import { IDataParsingService } from 'modules/recordLead/core/interfaces';

export class SpieDataParsingService implements IDataParsingService {
  parseBadgeData(data: string): ConferenceBadgeData {
    const dataArr = data.split(';');
    return {
      firstName: dataArr[1],
      lastName: dataArr[2],
      id: dataArr[3],
      eventCode: dataArr[4],
      badgeNumber: dataArr[0],
    };
  }
}
