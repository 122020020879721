import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FollowUpPriority, FollowUpProcessData } from 'api-client/api';
import { db, NO_CONNECTIVITY_ERROR } from 'modules/common/core';
import { v4 as uuidv4 } from 'uuid';
import { User } from 'api-client/api';
import { ConferenceBadgeData, ConferenceParticipantData } from 'api-client/api';
import { AppState, AppThunk } from 'store/store';
import { resetBadgeScanning, setCustomerDataEntry } from '../../common/core/appConfigSlice';
import { ServicesState } from '../../common/context';
import { LeadRecordingState, LeadCustomerFormData, LeadCustomerCrmData, SalesNotes } from './sliceTypes';
import { ErrorMessage, SimpleCheckboxOptions } from 'modules/common/core/types';
import { mergeAddressComponents } from '../utils/DataHandler';
import { serviceFactory } from 'modules/common/utils';

const initialState: LeadRecordingState = {
  error: undefined,
  recordingStarted: false,
  customerDataLoading: false,
  brochures: {},
  links: {},
  manuals: {},
  organizations: {},
  customerIndustries: {},
  marketingChannels: {},
  customerRequirements: {},
  customerProductInterests: {},
  leadCustomer: {
    language: LeadCustomerFormData.LanguageEnum.En,
    address: '',
    companyName: '',
    countryCode: '',
    email: '',
    fullName: '',
    phone: '',
    position: '',
    salutation: LeadCustomerFormData.SalutationEnum.Empty,
  },
  sessionId: uuidv4(),
  followUpPriority: FollowUpPriority.Medium,
  followUpProcess: {
    createCase: true,
    sendInformationEmail: true,
    customizeInformationEmail: false,
    waitForCustomer: true,
  },
};

export const leadRecordingSlice = createSlice({
  name: 'leadRecording',
  initialState: initialState,
  reducers: {
    setLeadToEdit: (_, action: PayloadAction<LeadRecordingState>) => {
      const metadataService = serviceFactory.createMetadataService(action.payload.targetedOrganization);
      return {
        ...action.payload,
        recordingStarted: true,
        brochures: Object.assign(
          {},
          ...metadataService.getBrochures().map((x) => ({ [x]: action.payload.brochures[x] }))
        ),
        links: Object.assign({}, ...metadataService.getLinks().map((x) => ({ [x]: action.payload.links[x] }))),
        manuals: Object.assign({}, ...metadataService.getManuals().map((x) => ({ [x]: action.payload.manuals[x] }))),
        organizations: Object.assign(
          {},
          ...metadataService.getOrganizations().map((x) => ({ [x]: action.payload.organizations[x] }))
        ),
        customerIndustries: Object.assign(
          {},
          ...metadataService.getCustomerIndustries().map((x) => ({ [x]: action.payload.customerIndustries[x] }))
        ),
        marketingChannels: Object.assign(
          {},
          ...metadataService.getMarketingChannels().map((x) => ({ [x]: action.payload.marketingChannels[x] }))
        ),
        customerRequirements: Object.assign(
          {},
          ...metadataService.getCustomerRequirements().map((x) => ({ [x]: action.payload.customerRequirements[x] }))
        ),
        customerProductInterests: Object.assign(
          {},
          ...metadataService
            .getCustomerProductInterest()
            .map((x) => ({ [x]: action.payload.customerProductInterests[x] }))
        ),
      };
    },
    startLeadRecording: (state, action: PayloadAction<string | undefined>) => {
      state.recordingStarted = true;
      state.targetedOrganization = action.payload;
      const metadataService = serviceFactory.createMetadataService(action.payload);
      state.brochures = Object.assign({}, ...metadataService.getBrochures().map((x) => ({ [x]: undefined })));
      state.links = Object.assign({}, ...metadataService.getLinks().map((x) => ({ [x]: undefined })));
      state.manuals = Object.assign({}, ...metadataService.getManuals().map((x) => ({ [x]: undefined })));
      if (!action.payload) {
        state.organizations = Object.assign({}, ...metadataService.getOrganizations().map((x) => ({ [x]: undefined })));
      } else {
        state.organizations = { [action.payload]: true };
      }
      state.customerIndustries = Object.assign(
        {},
        ...metadataService.getCustomerIndustries().map((x) => ({ [x]: undefined }))
      );
      state.marketingChannels = Object.assign(
        {},
        ...metadataService.getMarketingChannels().map((x) => ({ [x]: undefined }))
      );
      state.customerRequirements = Object.assign(
        {},
        ...metadataService.getCustomerRequirements().map((x) => ({ [x]: undefined }))
      );
      state.customerProductInterests = Object.assign(
        {},
        ...metadataService.getCustomerProductInterest().map((x) => ({ [x]: undefined }))
      );
    },
    setError(state, action: PayloadAction<ErrorMessage | undefined>) {
      if (!action.payload) {
        state.error = undefined;
        return;
      }

      const connectivityErrorShownValue = localStorage.getItem('connectivityErrorShown');

      if (action.payload === NO_CONNECTIVITY_ERROR && connectivityErrorShownValue === '1') {
        return;
      }

      state.error = action.payload;

      if (action.payload === NO_CONNECTIVITY_ERROR) {
        localStorage.setItem('connectivityErrorShown', '1');
      }
    },
    setScannedBadgeData(state, action: PayloadAction<ConferenceBadgeData>) {
      const leadCustomer: LeadCustomerFormData = {
        language: LeadCustomerFormData.LanguageEnum.En,
        salutation: LeadCustomerFormData.SalutationEnum.Empty,
      };
      leadCustomer.fullName = `${action.payload.firstName ?? ''} ${action.payload.lastName ?? ''}`;
      leadCustomer.nameComponents = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };
      return {
        ...state,
        leadCustomer: leadCustomer,
        conferenceParticipantId: action.payload.id,
      };
    },
    setLeadCustomerLoading(state, action: PayloadAction<boolean>) {
      state.customerDataLoading = action.payload;
    },
    setFullCustomerData(state, action: PayloadAction<ConferenceParticipantData>) {
      const leadCustomer: LeadCustomerFormData = {
        language: state.leadCustomer?.language ?? LeadCustomerFormData.LanguageEnum.En,
        salutation: LeadCustomerFormData.SalutationEnum.Empty,
      };
      leadCustomer.fullName = `${action.payload.firstName} ${action.payload.lastName}`;
      leadCustomer.companyName = action.payload.company;
      leadCustomer.nameComponents = {
        firstName: action.payload.firstName ?? '',
        lastName: action.payload.lastName ?? '',
      };
      leadCustomer.addressComponents = {
        street: action.payload.street ?? '',
        city: action.payload.city ?? '',
        state: action.payload.stateProvince ?? '',
        zip: action.payload.zipcode ?? '',
        countryName: action.payload.country ?? '',
      };
      leadCustomer.address = mergeAddressComponents(leadCustomer.addressComponents);
      leadCustomer.countryCode = action.payload.countryCode;
      leadCustomer.country = action.payload.country;
      leadCustomer.email = action.payload.email;
      leadCustomer.phone = action.payload.phone;
      leadCustomer.position = action.payload.jobTitle;
      leadCustomer.dataComplete = true;

      return {
        ...state,
        leadCustomer: leadCustomer,
      };
    },
    resetBadgeData(state) {
      return {
        ...state,
        leadCustomer: initialState.leadCustomer,
        conferenceParticipantId: undefined,
      };
    },
    setXimeaContact(state, action: PayloadAction<User>) {
      state.ximeaContact = action.payload;
      if (!state.followUpUser) {
        leadRecordingSlice.caseReducers.setFollowUpUser(state, action);
      }
    },
    setCustomerContact(state, action: PayloadAction<LeadCustomerFormData>) {
      state.leadCustomer = { ...action.payload };
    },
    setDataFromCRM(state, action: PayloadAction<LeadCustomerCrmData>) {
      state.leadCustomerCrmData = action.payload;
    },
    setSalesInformation(state, action: PayloadAction<SalesNotes>) {
      state.salesInformation = action.payload;
    },

    appendExpoApiDataToSalesInformation(state, action: PayloadAction<ConferenceParticipantData>) {
      const projectInfo = action.payload.productApplication
        ? `Product or research application: ${action.payload.productApplication}`
        : undefined;
      const usedProducts = action.payload.usedProducts
        ? `Used and recommended products: ${action.payload.usedProducts}`
        : undefined;
      const jobFunction = action.payload.jobDescription
        ? `Customer primary job function: ${action.payload.jobDescription}`
        : undefined;
      if (!projectInfo && !jobFunction && !usedProducts) {
        return;
      }
      const salesInformation: SalesNotes = {
        otherNotes: [state.salesInformation?.otherNotes, jobFunction].filter(Boolean).join('\n'),
        projectInformation: [state.salesInformation?.projectInformation, projectInfo, usedProducts]
          .filter(Boolean)
          .join('\n'),
      };
      state.salesInformation = salesInformation;
    },
    reset(state) {
      return {
        ...initialState,
        ximeaContact: state.ximeaContact,
        followUpUser: state.ximeaContact,
        sessionId: uuidv4(),
      };
    },
    setBrochures(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.brochures = action.payload;
    },
    setManuals(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.manuals = action.payload;
    },
    setLinks(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.links = action.payload;
    },
    setRequirements(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.customerRequirements = action.payload;
    },
    setIndustries(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.customerIndustries = action.payload;
    },
    setMarketingChannels(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.marketingChannels = action.payload;
    },
    setCustomerProductInterests(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.customerProductInterests = action.payload;
    },

    setCustomMarketingChannel(state, action: PayloadAction<string>) {
      state.customMarketingChannel = action.payload;
    },
    setCustomRequirement(state, action: PayloadAction<string>) {
      state.customRequirement = action.payload;
    },
    setCustomProductInterest(state, action: PayloadAction<string>) {
      state.customProductInterest = action.payload;
    },
    setCustomIndustry(state, action: PayloadAction<string>) {
      state.customIndustry = action.payload;
    },

    setFollowUpUser(state, action: PayloadAction<User>) {
      if (state.targetedOrganization && !action.payload.allowedOrganizations.includes(state.targetedOrganization)) {
        state.followUpUser = undefined;
        return;
      }
      state.followUpUser = action.payload;
      if (state.targetedOrganization) {
        return;
      }
      const metadataService = serviceFactory.createMetadataService(state.targetedOrganization);
      metadataService.getOrganizations().forEach((org: string) => {
        state.organizations[org] = org == action.payload.defaultOrganization;
      });
    },
    setFollowUpPriority(state, action: PayloadAction<FollowUpPriority>) {
      state.followUpPriority = action.payload;
    },
    setFollowUpProcess(state, action: PayloadAction<FollowUpProcessData>) {
      state.followUpProcess = action.payload;
    },
    setOrganizations(state, action: PayloadAction<SimpleCheckboxOptions>) {
      state.organizations = action.payload;
    },
    setAdditionalNotes(state, action: PayloadAction<string>) {
      state.salesTasks = action.payload;
    },
    setBackofficeTasks(state, action: PayloadAction<string | undefined>) {
      state.backofficeTasks = action.payload;
    },
    setContactCardImageUrl(state, action: PayloadAction<string | undefined>) {
      state.contactCardImageUrl = action.payload;
    },
    setTempContactCardImageUrl(state, action: PayloadAction<string | undefined>) {
      state.contactCardImageUrl = action.payload;
    },
  },
});

export const storeAndReset =
  (stepNr: number): AppThunk =>
  async (dispatch, getState) => {
    const state = getState().leadRecording;
    if (!state.sessionId) return;
    dispatch(reset());
    dispatch(resetBadgeScanning());
    const exists = await db.leads.get(state.sessionId);
    if (exists) {
      await db.leads.update(state.sessionId, {
        company: state.leadCustomer?.companyName ?? 'Unknown company',
        customer: state.leadCustomer?.fullName ?? 'Unknown customer',
        leadContent: JSON.stringify(state),
        lastModifiedOn: new Date(),
        step: stepNr,
      });
    } else {
      await db.leads.add({
        id: state.sessionId,
        company: state.leadCustomer?.companyName ?? 'Unknown company',
        customer: state.leadCustomer?.fullName ?? 'Unknown customer',
        leadContent: JSON.stringify(state),
        lastModifiedOn: new Date(),
        step: stepNr,
      });
    }
  };

export const fetchFullData =
  (input: string, services: ServicesState): AppThunk =>
  async (dispatch, getState) => {
    if (getState().leadRecording.customerDataLoading) return;
    dispatch(setLeadCustomerLoading(true));
    const state = getState();
    if (state.appConfig.features.expoApiAvailable && services.dataParsingService) {
      const parsedData = services.dataParsingService.parseBadgeData(input);
      if (parsedData) {
        dispatch(setScannedBadgeData(parsedData));
      }
    }

    try {
      const result = await services.conferenceDataService?.fetchConferenceParticipantData(input);
      if (result) {
        dispatch(setFullCustomerData(result));
        dispatch(appendExpoApiDataToSalesInformation(result));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLeadCustomerLoading(false));
      dispatch(setCustomerDataEntry(false));
    }
  };

export const {
  reset,
  setScannedBadgeData,
  setFullCustomerData,
  setLeadCustomerLoading,
  setFollowUpPriority,
  setFollowUpProcess,
  appendExpoApiDataToSalesInformation,
} = leadRecordingSlice.actions;

/**
 * Selector for the follow up priority that has been set for the current lead.
 * @param state State of the application
 * @returns A value determining the follow up priority
 */
export const followUpPrioritySelector = (state: AppState): FollowUpPriority | undefined =>
  state.leadRecording.followUpPriority;

/**
 * Gets the value of the followUpProcess object
 * @param state State of the application
 * @returns the follow up process configuration
 */
export const followUpProcessSelector = (state: AppState): FollowUpProcessData => state.leadRecording.followUpProcess;

/**
 * Selector for the sales tasks specified for the current lead.
 * @param state State of the application
 * @returns A string containing the sales tasks
 */
export const salesTasksSelector = (state: AppState): string | undefined => state.leadRecording.salesTasks;

/**
 * Selector for the backoffice tasks specified for the current lead.
 * @param state State of the application
 * @returns A list of checkbox options containing the backoffice tasks
 */
export const manualsSelector = (state: AppState): SimpleCheckboxOptions => state.leadRecording.manuals;

export const customerDataLoadingSelector = (state: AppState): boolean => state.leadRecording.customerDataLoading;

export const targetedOrganizationSelector = (state: AppState): string | undefined =>
  state.leadRecording.targetedOrganization;

export const recordingStartedSelector = (state: AppState): boolean => state.leadRecording.recordingStarted;
